import { useEffect, lazy, Suspense } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./App.css";
import "./Styled/styles.scss";
const Layout = lazy(() => import("./Views/Admin/Layout"));
const Login = lazy(() => import("./Views/Admin/Login"));
const Artikel = lazy(() => import("./Views/Client/Artikel"));
const BeritaPage = lazy(() => import("./Views/Client/BeritaPage"));
const DetailBerita = lazy(() => import("./Views/Client/DetailBerita"));
const DetailProgram = lazy(() => import("./Views/Client/DetailProgram"));
const Donasi = lazy(() => import("./Views/Client/Donasi"));
const DonPembayaran = lazy(() => import("./Views/Client/DonPembayaran"));
const FormKemitraan = lazy(() => import("./Views/Client/FormKemitraan"));
const Gallery = lazy(() => import("./Views/Client/Gallery"));
const Kemitraan = lazy(() => import("./Views/Client/Kemitraan"));
const LandingPage = lazy(() => import("./Views/Client/LandingPage"));
const LihatMitra = lazy(() => import("./Views/Client/LihatMitra"));
const Pembayaran = lazy(() => import("./Views/Client/Pembayaran"));
const Program = lazy(() => import("./Views/Client/Program"));
const Download = lazy(() => import("./Views/Client/Download"));

function App() {
  const history = useHistory();

  useEffect(() => {
    // history.go(0)
  }, []);

  return (
    <>
      <Suspense fallback={<div>...</div>}>
        <Switch>
          <Route path="/admin/loged" component={Layout} />
          <Route path="/admin" component={Login} />
          <Route path="/donpembayaran" component={DonPembayaran} />
          <Route path="/pembayaran/:nominal" component={Pembayaran} />
          <Route path="/donasi/:kategori" component={Donasi} />
          <Route exact path="/donasi" component={Donasi} />
          <Route path="/download" component={Download} />
          <Route path="/artikel/:id" component={DetailBerita} />
          <Route path="/artikel" component={Artikel} />
          <Route path="/galeri" component={Gallery} />
          <Route path="/program/:id/:title" component={DetailProgram} />
          <Route path="/program" component={Program} />
          <Route path="/kemitraan/daftar/:kategori" component={FormKemitraan} />
          <Route path="/kemitraan/lihat/:kategori" component={LihatMitra} />
          <Route path="/kemitraan" component={Kemitraan} />
          <Route path="/berita/:id" component={DetailBerita} />
          <Route path="/berita" component={BeritaPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    donatur: [],
    program: null,
    keterangan: [],
    kemitraan: null,
    media: [],
    berita10: null,
    berita: null,
    artikel: null,
    banner: [],
    artikelLanding: null,
    beritaLanding: null,
    penerimaDonasi: [],
    transaksi: null,
    galeri: null,
    download: [],
    manfaat: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGEDONATUR':
            return { ...state, donatur: action.payload };
        case 'CHANGEMANFAAT':
            return { ...state, manfaat: action.payload };
        case 'CHANGEPROGRAM':
            return { ...state, program: action.payload };
        case 'CHANGEKETERANGAN':
            return { ...state, keterangan: action.payload };
        case 'CHANGEKEMITRAAN':
            return { ...state, kemitraan: action.payload };
        case 'CHANGEMEDIA':
            return { ...state, media: action.payload };
        case 'CHANGEBERITA':
            return { ...state, berita: action.payload };
        case 'CHANGEBERITA10':
            return { ...state, berita10: action.payload };
        case 'CHANGEARTIKEL':
            return { ...state, artikel: action.payload };
        case 'CHANGEBANNER':
            return { ...state, banner: action.payload };
        case 'CHANGEARTKELLANDING':
            return { ...state, artikelLanding: action.payload };
        case 'CHANGEBERITALANDING':
            return { ...state, beritaLanding: action.payload };
        case 'CHANGE_PENERIMA_DONASI':
            return { ...state, penerimaDonasi: action.payload };
        case 'CHANGETRANSAKSI':
            return { ...state, transaksi: action.payload };
        case 'CHANGEGALERI':
            return { ...state, galeri: action.payload };
        case 'CHANGEDOWNLOAD':
            return { ...state, download: action.payload };
        case 'LOGOUT':
            return {
                donatur: [],
                program: [],
                keterangan: [],
                kemitraan: [],
                media: [],
                berita: [],
                banner: [],
                artikelLanding: [],
                beritaLanding: [],
                penerimaDonasi: [],
                transaksi: [],
                galeri: [],
                download: []
            };
        default:
            return state;
    }
};
